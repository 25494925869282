//Install
import React, { Component } from "react";
//Install
import Team from "../../Media/Image/002.jpg";
//Install
import propTypes from "prop-types";

//Element
class Three extends Component {
    //Element
    render() {
        //Element
        const { ID, Image } = this.props
        //Element
        let URL
        //Element
        URL = "https://media.openworldcargames.com/content/"
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Ki05M5" },
            //Element
            React.createElement( "figure", { className: "float-start w-100 p-0 m-0 e4K33N", style: { backgroundImage: "url( "+ Team +" )" } } ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center position-absolute start-0 top-0 z3S713" },
                //Element
                React.createElement( "h6", { className: "float-start w-auto p-0 m-0" },
                    //Element
                    "Would you like to talk to us about open positions and future plans?"
                ),
                //Element
                React.createElement( "a", { className: "float-start p-0 m-0 mt-2 text-center text-uppercase", href: "mailto:info@openworldcargames.com" },
                    //Element
                    "Contact Us Anytime."
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
Three.propTypes = {
    ID    : propTypes.string.isRequired,
    Image : propTypes.string.isRequired
}
//Element
export default Three;