//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";

//Element
class Policy extends Component {
    //Element
    Custom = () => {
        //Element
        let Header = document.getElementsByClassName( "x1E6p6" )[0]
        //Element
        let Footer = document.getElementsByClassName( "eB9032" )[0]
        //Element
        Header.classList.add( "d-none" )
        //Element
        Footer.classList.add( "d-none" )
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Privacy Policy  - OWCG Studios"
        //Element
        this.Custom()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 nF5A00" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 o772w5" },
                            //Element
                            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 mt-5 mb-4 lh-1 text-center x914i1" },
                                //Element
                                "Privacy Policy"
                            ),
                            //Element
                            React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-4 text-center g4x76h" },
                                //Element
                                "For more information, please contact us!"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center u948a7" },
                                //Element
                                "This Privacy Policy governs the manner in which bariskaplan collects, uses, maintains and discloses information collected from users (each, a “User”) of the Services offered by bariskaplan. This privacy policy applies to all mobile games and other products offered by bariskaplan."
                            ),
                            //Element
                            React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-4 text-center g4x76h" },
                                //Element
                                "Personal identification information"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center u948a7" },
                                //Element
                                "We may collect personal identification information from Users in a variety of ways in connection with activities, services, features or resources we make available on our Services. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain activities."
                            ),
                            //Element
                            React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-4 text-center g4x76h" },
                                //Element
                                "Non-personal identification information"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center u948a7" },
                                //Element
                                "We may collect non-personal identification information about Users whenever they interact with our Services. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Services, such as the operating system and the Internet service providers utilized and other similar information."
                            ),
                            //Element
                            React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-4 text-center g4x76h" },
                                //Element
                                "How we use collected information"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center u948a7" },
                                //Element
                                "bariskaplan may collect and use Users personal information for the following purposes:",
                                //Element
                                React.createElement( "br", {} ),
                                //Element
                                "Information you provide helps us respond to your customer service requests and support needs more efficiently.",
                                //Element
                                React.createElement( "br", {} ),
                                //Element
                                "To improve our Site",
                                //Element
                                React.createElement( "br", {} ),
                                //Element
                                "We may use feedback you provide to improve our products and services.",
                                //Element
                                React.createElement( "br", {} ),
                                //Element
                                "To send periodic emails",
                                //Element
                                React.createElement( "br", {} ),
                                //Element
                                "We may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc."
                            ),
                            //Element
                            React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-4 text-center g4x76h" },
                                //Element
                                "Sharing your personal information"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center u948a7" },
                                //Element
                                "We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above."
                            ),
                            //Element
                            React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-4 text-center g4x76h" },
                                //Element
                                "Third party websites"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center u948a7" },
                                //Element
                                "Users may find advertising or other content on our Services that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Services. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Services, is subject to that website’s own terms and policies."
                            ),
                            //Element
                            React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-4 text-center g4x76h" },
                                //Element
                                "Advertising"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center u948a7" },
                                //Element
                                "Ads appearing on our services may be delivered to Users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile non personal identification information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This privacy policy does not cover the use of cookies by any advertisers."
                            ),
                            //Element
                            React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-4 text-center g4x76h" },
                                //Element
                                "Google Adsense"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center u948a7" },
                                //Element
                                "Some of the ads may be served by Google. Google’s use of the DART cookie enables it to serve ads to Users based on their visit to our Site and other sites on the Internet. DART uses “non personally identifiable information” and does NOT track personal information about you, such as your name, email address, physical address, etc. You may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy at http://www.google.com/privacy_ads.html"
                            ),
                            //Element
                            React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-4 text-center g4x76h" },
                                //Element
                                "Changes to this privacy policy"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center u948a7" },
                                //Element
                                "bariskaplan has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications."
                            ),
                            //Element
                            React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-4 text-center g4x76h" },
                                //Element
                                "Your acceptance of these terms"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center u948a7" },
                                //Element
                                "By using our Services, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Services. Your continued use of our Services following the posting of changes to this policy will be deemed your acceptance of those changes."
                            ),
                            //Element
                            React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-4 text-center g4x76h" },
                                //Element
                                "Contact Details"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 mb-5 text-center u948a7" },
                                //Element
                                "If you have any questions about this Privacy Policy, the practices of our Services, or your dealings with our Services, please contact us at: info@openworldcargames.com"
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Policy;