//Install
import React, { Component } from "react";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { IoEarthOutline, IoShieldCheckmarkOutline, IoMedicalOutline, IoPlayCircleOutline, IoStarOutline, IoPieChartOutline } from "react-icons/io5";
//Install
import Two from "./Content/Two";
//Install
import Three from "./Content/Three";

//Element
class Main extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "2021 - 2023 © - OWCG Studios"
    }
    //Element
    render() {
        //Element
        const Two_Slider = {
            //Element
            spaceBetween  : "0",
            //Element
            slidesPerView : "1",
            //Element
            navigation    : {},
            //Element
            autoplay      : {
                //Element
                delay     : 5000
            },
            //Element
            modules       : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Three_Slider = {
            //Element
            spaceBetween  : "0",
            //Element
            slidesPerView : "1",
            //Element
            pagination    : {
                //Element
                clickable : true
            },
            //Element
            navigation    : {},
            //Element
            autoplay      : {
                //Element
                delay     : 5000
            },
            //Element
            modules       : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 nF5A00" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 y60J46" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 dU9167" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 text-center" },
                                    //Element
                                    "Making games is the closest thing we have to creating our own universe today"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 text-center" },
                                    //Element
                                    "- Mert Sezer"
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 mb-2 Bk976Y" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 Fo342l" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 text-center" },
                                                //Element
                                                "5"
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-center text-uppercase text-truncate" },
                                                //Element
                                                "Project"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 Fo342l" },
                                            //Element
                                            React.createElement( "h4", { id: "Download", className: "float-start w-100 p-0 m-0 text-center" },
                                                //Element
                                                "50"
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-center text-uppercase text-truncate" },
                                                //Element
                                                "Download"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 Fo342l" },
                                            //Element
                                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 text-center" },
                                                //Element
                                                "400000"
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-center text-uppercase text-truncate" },
                                                //Element
                                                "Daily Active User"
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 position-relative Z9c59A" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 position-relative Ve3477" },
                                //Element
                                React.createElement( "h3", { className: "float-start w-100 p-0 m-0 text-center text-uppercase" },
                                    //Element
                                    "Innovation Technology"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-center" },
                                    //Element
                                    "Become the Top 1 Technology Corporation in Turkey with the Global Presence"
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 rE804F" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 xE805J" },
                                                //Element
                                                React.createElement( "h6", { className: "float-start w-auto p-0 m-0 me-3 text-white" },
                                                    //Element
                                                    "250 Country Top Charts"
                                                ),
                                                //Element
                                                React.createElement( IoEarthOutline, {} )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 yC670W" },
                                                //Element
                                                React.createElement( "h6", { className: "float-start w-auto p-0 m-0 me-3 text-white" },
                                                    //Element
                                                    "Low Size Technology"
                                                ),
                                                //Element
                                                React.createElement( IoShieldCheckmarkOutline, {} )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 xE805J" },
                                                //Element
                                                React.createElement( "h6", { className: "float-start w-auto p-0 m-0 me-3 text-white" },
                                                    //Element
                                                    "High Recycle"
                                                ),
                                                //Element
                                                React.createElement( IoMedicalOutline, {} )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0" },
                                                //Element
                                                React.createElement( "img", { alt: "Innovation On Mobile", title: "Innovation On Mobile", src: "https://media.openworldcargames.com/content/007.png", className: "float-start w-100 h-auto", width: "100", height: "100" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 xE805J" },
                                                //Element
                                                React.createElement( IoPlayCircleOutline, {} ),
                                                //Element
                                                React.createElement( "h6", { className: "float-start w-auto p-0 m-0 ms-3 text-white" },
                                                    //Element
                                                    "High Optimization"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 yC670W" },
                                                //Element
                                                React.createElement( IoStarOutline, {} ),
                                                //Element
                                                React.createElement( "h6", { className: "float-start w-auto p-0 m-0 ms-3 text-white" },
                                                    //Element
                                                    "High Compatibility"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 xE805J" },
                                                //Element
                                                React.createElement( IoPieChartOutline, {} ),
                                                //Element
                                                React.createElement( "h6", { className: "float-start w-auto p-0 m-0 ms-3 text-white" },
                                                    //Element
                                                    "High Engineering"
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 Xo2C78" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 p8O94M" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Two_Slider },
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Two, { ID: "0", Title: "Open World Car Games", Image: "008.jpg" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Two, { ID: "0", Title: "Open World Car Games", Image: "009.jpg" } )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-5 qN430m" },
                                //Element
                                React.createElement( "h4", { className: "float-start w-100 p-0 m-0 text-center" },
                                    //Element
                                    "We Follow New Trends Very Closely"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center" },
                                    //Element
                                    "We have brought together high technology with low download sizes and we get high downloads in the global market with our games. Click to review all our games."
                                ),
                                //Element
                                React.createElement( "a", { className: "float-start p-0 m-0 mt-3 text-center", href: "https://play.google.com/store/apps/dev?id=8948069698432411703&hl=tr&gl=US" },
                                    //Element
                                    "See All Games"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 Ya5F02" },
                //Element
                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Three_Slider },
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( Three, { ID: "0", Image: "001.jpg" } )
                    ),
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( Three, { ID: "0", Image: "002.png" } )
                    )
                )
            )
        )
        //Element
        return (
            <main className="float-start w-100 position-relative">
                { Content }
            </main>
        );
    }
}
//Element
export default Main;