//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Welcome from "../Media/Video/001.mp4"

//Element
class Header extends Component {
    //Element
    Home = () => {
        //Element
        window.location.href = "/"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 x1E6p6" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 jM8583" },
                //Element
                React.createElement( "button", { id: "Header_Brand", name: "Header_Brand", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 position-relative", type: "button", onClick: this.Home },
                    //Element
                    React.createElement( "img", { alt: "Open World Car Games", title: "Open World Car Games", src: "https://media.openworldcargames.com/brand/002.png", width: "100", height: "auto" } )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 gI85l3" },
                //Element
                React.createElement( "video", { id: "Header_301612", autoPlay: true, loop: true, playsInline: true, muted: true, width: "100", height: "100" },
                    //Element
                    React.createElement( "source", { src: Welcome, type: "video/mp4" } )
                ),
                //Element
                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center position-absolute start-0 top-0 dI7075" },
                    //Element
                    React.createElement( "h1", { className: "float-start w-100 p-0 m-0 text-center text-uppercase fs-normal" },
                        //Element
                        "We Are Always Working For The Better"
                    )
                )
            )
        )
        //Element
        return (
            <header className="float-start w-100 position-relative">
                { Content }
            </header>
        );
    }
}
//Element
export default Header;