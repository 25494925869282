//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Element
import { IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin } from "react-icons/io5";
//Element
import { IoLocationOutline, IoMailOutline, IoGolfOutline } from "react-icons/io5";

//Element
class Footer extends Component {
    //Element
    render() {
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 eB9032" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 AgS723" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start Xm4170" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-truncate" },
                                    //Element
                                    React.createElement( IoLocationOutline, {} ),
                                    //Element
                                    "Efeler Mahallesi 1306 Sokak Numara : 3/2  - Didim / Aydın - Turkey"
                                ),
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-2 text-truncate" },
                                    //Element
                                    React.createElement( IoMailOutline, {} ),
                                    //Element
                                    "info@openworldcargames.com"
                                ),
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-2 text-truncate" },
                                    //Element
                                    React.createElement( IoGolfOutline, {} ),
                                    //Element
                                    "Open World Car Games LTD. "
                                ),
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-auto p-0 m-0 me-4 mt-4", href: "/" },
                                        //Element
                                        React.createElement( IoLogoFacebook, {} )
                                    ),
                                    //Element
                                    React.createElement( "a", { className: "float-start w-auto p-0 m-0 me-4 mt-4", href: "/" },
                                        //Element
                                        React.createElement( IoLogoInstagram, {} )
                                    ),
                                    //Element
                                    React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-4", href: "/" },
                                        //Element
                                        React.createElement( IoLogoLinkedin, {} )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 h-100 p-0 pt-5 pb-5 m-0 do6Q94" },
                                //Element
                                React.createElement( "iframe", { src: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3170.366621627719!2d27.256674015306356!3d37.38116117983304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzfCsDIyJzUyLjIiTiAyN8KwMTUnMzEuOSJF!5e0!3m2!1str!2str!4v1689539705298!5m2!1str!2str", width: "600", height: "450", className: "float-start w-100 h-100 p-0 m-0 border-0" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 Md5M31" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 iL622B" },
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-center text-truncate" },
                                    //Element
                                    "2021 - 2023 © - OWCG Studios"
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <footer className="float-start w-100 position-relative">
                { Content }
            </footer>
        );
    }
}
//Element
export default Footer;